<template>
  <div class="mb-5 p-0 mx-0" style="background-color:#f3f6f9 !important;">
    <!--begin::Nav Tabs-->
    <ul class="dashboard-tabs nav nav-pills nav-primary row row-paddingless m-0 p-0 flex-column flex-sm-row samples-top-nav-master" style="background-color:#f3f6f9 !important;"
        role="tablist">
      <li @click="onChangeNav(1)" class="nav-item d-flex col-sm flex-grow-1 flex-shrink-0 mb-lg-0">
        <a class="nav-link border py-10 d-flex flex-grow-1 rounded flex-column align-items-center justify-content-center items-center"
           :class="{active: isActive(1)}"
           data-toggle="pill">
          <span class="nav-icon py-2 w-auto d-flex align-items-center justify-content-center items-center">
            <span
              class="nav-icons"
                v-html="getIconByKey('icons.sample.product', {
                    class: 'w-25px h-25px d-inline-block ' + isActive(1) ? '' : 'opacity-30',
                    style: isActive(1) ? '' : 'opacity: 0.4;'
                  })">
            </span>
              <span
                  class="nav-text font-size-lg font-weight-bolder d-inline-block text-center pl-2 text-xl nav-custom-text">{{
                  $t('general.product').toUpperCase()
                }}
            </span>
          </span>
        </a>
      </li>
      <li @click="onChangeNav(2)" class="nav-item d-flex col-sm flex-grow-1 flex-shrink-0 mb-lg-0">
        <a class="nav-link border py-10 d-flex flex-grow-1 rounded flex-column align-items-center justify-content-center items-center"
           :class="{active: isActive(2)}"
           data-toggle="pill">
          <span class="nav-icon py-2 w-auto d-flex align-items-center justify-content-center items-center">
            <span
              class="nav-icons"
                v-html="getIconByKey('icons.sample.fabric', {
                    class: 'w-25px h-25px d-inline-block ' + isActive(2) ? '' : 'opacity-40',
                    style: isActive(2) ? '' : 'opacity: 0.4;'
                  })">
            </span>
              <span
                  class="nav-text font-size-lg font-weight-bolder d-inline-block text-center pl-2 text-xl  nav-custom-text">{{
                  $t('general.fabric').toUpperCase()
                }}
            </span>
          </span>
        </a>
      </li>
      <li @click="onChangeNav(3)" class="nav-item d-flex col-sm flex-grow-1 flex-shrink-0 mb-lg-0">
        <a class="nav-link border py-10 d-flex flex-grow-1 rounded flex-column align-items-center justify-content-center items-center"
           :class="{active: isActive(3)}"
           data-toggle="pill">
          <span class="nav-icon py-2 w-auto d-flex align-items-center justify-content-center items-center">
            <span
                class="nav-icons"
                v-html="getIconByKey('icons.sample.accessory', {
                    class: 'w-25px h-25px d-inline-block ' + isActive(3) ? '' : 'opacity-40',
                    style: isActive(3) ? '' : 'opacity: 0.4;'
                  })">
            </span>
              <span
                  class="nav-text font-size-lg font-weight-bolder d-inline-block text-center pl-2 text-xl  nav-custom-text">{{
                  $t('general.accessory').toUpperCase()
                }}
            </span>
          </span>
        </a>
      </li>
      <li @click="onChangeNav(null)" class="nav-item d-flex col-sm flex-grow-1 flex-shrink-0 mb-lg-0">
        <a class="nav-link border py-10 d-flex flex-grow-1 rounded flex-column align-items-center justify-content-center items-center"
           :class="{active: isActive(null)}"
           data-toggle="pill">
          <span class="nav-icon py-2 w-auto d-flex align-items-center justify-content-center items-center">
            <span
              class="nav-icons"
                v-html="getIconByKey('icons.sample.all', {
                    class: 'w-25px h-25px d-inline-block ' + isActive(null) ? '' : 'opacity-40',
                    style: isActive(null) ? '' : 'opacity: 0.4;'
                  })">
            </span>
              <span
                  class="nav-text font-size-lg font-weight-bolder d-inline-block text-center pl-2 text-xl  nav-custom-text">{{
                  $t('general.all').toUpperCase()
                }}
            </span>
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SampleIndexNavPanel",
  props: {
    activeNav: {
      default: null,
    }
  },
  methods: {
    isActive(value) {
      if (value == null && (this.activeNav == "null" || this.activeNav == null)) return true;
      if (this.activeNav == value) return true;
      else {
        return false;
      }
    },
    onChangeNav(value) {
      if (value == this.activeNav) return;
      this.$emit('changeActiveNav', value);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.demo.scss';
$primary-blue:#66c7e8;
.svg-icon.svg-icon-2x svg {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.nav-link {
  padding: 0.5rem 0.5rem !important;
}

.dashboard-tabs li {
  cursor: pointer;
}

.text-xl {
  font-size: 1.3rem !important;
}

.nav.nav-pills.nav-primary .show > .nav-link .nav-icon .svg-icon svg g [fill], .nav.nav-pills.nav-primary .nav-link.active .nav-icon .svg-icon svg g [fill] {
  fill: #000 !important;
}

.nav.nav-pills.nav-primary .nav-link.active {
  color: $primary-blue !important;
  background-color: transparent !important;
}

.nav.nav-pills.nav-primary .show > .nav-link .nav-text, .nav.nav-pills.nav-primary .nav-link:hover:not(.active) .nav-text {
  color: $primary-blue !important;
}

.nav.nav-pills.nav-primary .nav-link.active .nav-text {
  color: $primary-blue !important;
}

.nav.nav-pills.nav-primary .show > .nav-link, .nav.nav-pills.nav-primary .nav-link:hover:not(.active) {
  color: $primary-blue !important;
  background-color: transparent;
}

.nav.nav-pills.nav-primary .show > .nav-link .nav-icon .svg-icon svg g [fill], .nav.nav-pills.nav-primary .nav-link:hover:not(.active) .nav-icon .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: $primary-black-hover;
}

.dashboard-tabs {
  .nav-item {
    margin-right: 0;

    .border {
      border-radius: 0 !important;
      border: none !important;
      border-right: 2px solid #4b4e5f !important;
      border-bottom: 2px solid #4b4e5f !important;
    }

    &:last-child .border {
      border-right: none !important;
    }
  }
}


  .dashboard-tabs {
    display: flex;
    flex-direction: row !important;
  }
  .dashboard-tabs > li{
    max-width: 25%;
  }
  .dashboard-tabs .nav-item .border{
    border:none !important;
    border-bottom: 1px solid $primary-blue !important;
  }
  .nav-icons{
   display: none !important;
  }
  .nav-custom-text{
    font-size: 12px !important;
  }
  .samples-top-nav-master{
    border-top: 1px  solid #66c7e8 !important;
    border-right: 1px  solid #66c7e8 !important;
    border-left: 1px  solid #66c7e8 !important;
    border-top-left-radius:12px;
    border-top-right-radius:12px;
    z-index:9999;
  }
</style>