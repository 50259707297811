<template>
  <div class="row csr-edit">
    <div class="col-md-12">
      <div id="filter-card" class="row justify-content-center items-baseline w-100 mb-2" style="margin-bottom: -5px;">
        <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)">
          <i class="fa fa-chevron-left" style="color: #78cdea"></i>
        </span>
        <h3 id="page-title" class="d-inline-block">{{ $t('csr.self') }}</h3>
      </div>
    </div>
    <div class="col-12 csr-card-body">
      <dashboard-box id="mainDiv" :show-title="true"  title="Firma Bilgileri">
        <template slot="title">
          <h3>Firma Bilgileri</h3>
        </template>
        <template slot="preview">
          <div class="p-2 d-flex">
            <div class="flex-grow-1 row p-3">
              <div class="col-12">
                <custom-multi-select
                    :helperText="$t('general.please_select')"
                    :is-inline="false"
                    :max="1"
                    :model.sync="form.supplier_company_id"
                    :not-list="true"
                    :options="supplierCompanies"
                    :required="true"
                    :title="$t('csr.supplier_company')"
                    class="font-weight-bolder"
                    input-max-width="100%"
                    input-width="100%"
                    name="supplier_company"
                    width-class="100%"
                />
              </div>
              <div>
              </div>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>

    <div class="col-12 csr-card-body">
      <dashboard-box id="companyDiv" :show-title="true" >
        <template slot="title">
          <h3>{{ $t('csr.company_title') }}</h3>
        </template>
        <template slot="preview">
          <div class="p-2 d-flex">
            <div class="flex-grow-1 row p-3">
              <div class="col-12">
                <custom-multi-select
                    :helperText="$t('general.please_select')"
                    :is-inline="false"
                    :model.sync="form.company_id"
                    :options="companies"
                    :required="true"
                    :title="$t('csr.company')"
                    class="font-weight-bolder"
                    input-max-width="100%"
                    input-width="100%"
                    name="company"
                    width-class="100%"
                />
              </div>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>

    <div class="col-12 csr-card-body">
      <dashboard-box id="disney_licences" :show-title="true" >
        <template slot="title">
          <h3>{{ $t('csr.disney_licences') }}</h3>
        </template>
        <template slot="preview">
          <div class="p-2 d-flex">
            <div class="flex-grow-1 row p-3">
              <div class="col-12">
                <custom-multi-select
                    :helperText="$t('general.please_select')"
                    :is-inline="false"
                    :max="1"
                    :model.sync="form.disney_licences.disney_id"
                    :not-list="true"
                    :options="yesNoOptions"
                    :required="true"
                    :title="$t('csr.disney')"
                    class="font-weight-bolder"
                    input-max-width="100%"
                    input-width="100%"
                    name="disney"
                    width-class="100%"
                />
              </div>
              <div class="col-12">
                <custom-multi-select
                    :helperText="$t('general.please_select')"
                    :is-inline="false"
                    :max="1"
                    :model.sync="form.disney_licences.license_id"
                    :not-list="true"
                    :options="disneyLicences"
                    :required="false"
                    :title="$t('csr.licence')"
                    class="font-weight-bolder"
                    input-max-width="100%"
                    input-width="100%"
                    name="licence"
                    width-class="100%"
                />
              </div>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>

    <div class="col-12 csr-card-body">
      <dashboard-box id="process" :show-title="true" >
        <template slot="title">
          <h3>{{ $t('csr.process_title') }}</h3>
        </template>
        <template slot="preview">
          <div class="p-2 d-flex">
            <div class="flex-grow-1 row p-3">
              <div class="col-12">
                <custom-multi-select
                    :helperText="$t('general.please_select')"
                    :is-inline="false"
                    :model.sync="form.process.supplier_type_id"
                    :options="supplierTypes"
                    :required="true"
                    :title="$t('csr.supplier_type')"
                    class="font-weight-bolder"
                    input-max-width="100%"
                    input-width="100%"
                    name="supplier_type"
                    width-class="100%"
                />
              </div>
              <div class="col-12">
                <custom-multi-select
                    :helperText="$t('general.please_select')"
                    :is-inline="false"
                    :model.sync="form.process.process_id"
                    :options="csrProcesses"
                    :required="true"
                    :title="$t('csr.process')"
                    class="font-weight-bolder"
                    input-max-width="100%"
                    input-width="100%"
                    name="process"
                    width-class="100%"
                />
              </div>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>


    <div class="col-12 csr-card-body">
      <dashboard-box id="audit" :show-title="true" >
        <template slot="title">
          <h3>{{ $t('csr.company_audit_result') }}</h3>
        </template>
        <template slot="preview">
          <div class="p-2 d-flex">
            <div class="flex-grow-1 row p-3">
              <div class="col-12">
                <custom-multi-select
                    :helperText="$t('general.please_select')"
                    :is-inline="false"
                    :max="1"
                    :model.sync="form.audit_results.audit_social_id"
                    :not-list="true"
                    :options="auditDataOptions"
                    :required="true"
                    :title="$t('csr.audit_social')"
                    class="font-weight-bolder"
                    input-max-width="100%"
                    input-width="100%"
                    name="audit_social"
                    width-class="100%"
                />
              </div>
              <div class="col-12">
                <custom-multi-select
                    :helperText="$t('general.please_select')"
                    :is-inline="false"
                    :max="1"
                    :model.sync="form.audit_results.audit_environment_id"
                    :not-list="true"
                    :options="auditDataOptions"
                    :required="true"
                    :title="$t('csr.audit_environment')"
                    class="font-weight-bolder"
                    input-max-width="100%"
                    input-width="100%"
                    name="audit_environment"
                    width-class="100%"
                />
              </div>

              <div class="col-12">
                <custom-multi-select
                    :helperText="$t('general.please_select')"
                    :is-inline="false"
                    :max="1"
                    :model.sync="form.audit_results.audit_metal_id"
                    :not-list="true"
                    :options="auditDataOptions"
                    :required="true"
                    :title="$t('csr.audit_metal')"
                    class="font-weight-bolder"
                    input-max-width="100%"
                    input-width="100%"
                    name="audit_metal"
                    width-class="100%"
                />
              </div>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>

    <div class="col-12 csr-card-body">
      <dashboard-box id="certifications" :show-title="true" >
        <template slot="title">
          <h3>{{ $t('csr.certifications') }}</h3>
        </template>
        <template slot="preview">
          <div class="p-2 d-flex">
            <div class="flex-grow-1 row p-3">
              <div class="col-12">
                <custom-multi-select
                    :helperText="$t('general.please_select')"
                    :is-inline="false"
                    :disabled="certificateStatus"
                    :model.sync="form.certifications.certificate_id"
                    :options="certificateOptions"
                    :required="true"
                    :title="$t('csr.certifications')"
                    class="font-weight-bolder"
                    input-max-width="100%"
                    input-width="100%"
                    name="certifications"
                    width-class="100%"
                />
              </div>

              <div class="col-12 mt-3">
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12" v-show="form.certifications.certificate_id.includes('1')">
                    <date-picker-input
                        :has-bottom-margin="false"
                        :is-inline="false"
                        :is-valid="form.certifications.ocs_end_date != null ? true : null"
                        :model.sync="form.certifications.ocs_end_date"
                        :required="true"
                        :title="$t('csr.ocs_end_date')"
                        input-max-width="100%"
                        input-width="100%"
                        label-class="text-lg"
                        style="background-color: #f3f6f9 !important;"
                    />

                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12" v-show="form.certifications.certificate_id.includes('3')">
                    <date-picker-input
                        :has-bottom-margin="false"
                        :is-inline="false"
                        :is-valid="form.certifications.gots_end_date != null ? true : null"
                        :model.sync="form.certifications.gots_end_date"
                        :required="true"
                        :title="$t('csr.gots_end_date')"
                        input-max-width="100%"
                        input-width="100%"
                        label-class="text-lg"
                        style="background-color: #f3f6f9 !important;"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 mt-3">
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12" v-show="form.certifications.certificate_id.includes('2')">
                    <date-picker-input
                        :has-bottom-margin="false"
                        :is-inline="false"
                        :is-valid="form.certifications.rcs_end_date != null ? true : null"
                        :model.sync="form.certifications.rcs_end_date"
                        :required="true"
                        :title="$t('csr.rcs_end_date')"
                        input-max-width="100%"
                        input-width="100%"
                        label-class="text-lg"
                        style="background-color: #f3f6f9 !important;"
                    />
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12" v-show="form.certifications.certificate_id.includes('4')">
                    <date-picker-input
                        :has-bottom-margin="false"
                        :is-inline="false"
                        :is-valid="form.certifications.grs_end_date != null ? true : null"
                        :model.sync="form.certifications.grs_end_date"
                        :required="true"
                        :title="$t('csr.grs_end_date')"
                        input-max-width="100%"
                        input-width="100%"
                        label-class="text-lg"
                        style="background-color: #f3f6f9 !important;"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
    <div class="col-12 csr-card-body">
      <dashboard-box id="join_life" :show-title="true" >
        <template slot="title">
          <h3>{{ $t('csr.join_life_title') }}</h3>
        </template>
        <template slot="preview">
          <div class="p-2 d-flex">
            <div class="flex-grow-1 row p-3">
              <div class="col-12">
                <custom-multi-select
                    :helperText="$t('general.please_select')"
                    :is-inline="false"
                    :model.sync="form.join_life"
                    :options="joinLifeOptions"
                    :required="true"
                    :title="$t('csr.join_life_spec')"
                    class="font-weight-bolder"
                    input-max-width="100%"
                    input-width="100%"
                    name="join_life"
                    width-class="100%"
                />
              </div>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>

    <div class="col-12 csr-card-body">
      <dashboard-box id="company_status"  :show-title="true">
        <template slot="title">
          {{$t('csr.company_status') }}
        </template>
        <template slot="preview">
          <div class="p-2 d-flex mt-4">
            <div class="flex-grow-1 row p-3">
              <div class="col-12">
                <div class="row d-flex justify-content-between p-3">
                  <span> <h3> {{$t('csr.company_block') }} </h3> </span>
                  <b-form-checkbox v-model="form.status" name="check-button" switch size="lg" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>

    <div class="col-12 text-center">
      <div class="col flex-grow-1 p-3">
        <div class="flex-grow-1 justify-content-center row">
          <div class="d-flex justify-content-center mt-3">
            <span
                class="px-3 green-border-1px rounded-full d-flex justify-content-center items-center w-125px mx-2 cursor-pointer"
                @click="$router.push({name: 'csr.index'})">
              <span v-html="getIconByKey('icons.waybill.give_up', {
                class: 'w-35px h-35px object-cover d-inline-block opacity-75'})">
              </span>
                {{ $t('general.give_up').toUpperCase() }}
              </span>
            <button-with-icon
                :disabled="!isUserGranted('Csr', ['create', 'update'], false)"
                :size="'lg'"
                :text="$t('general.save').toUpperCase()"
                icon-name="icons.waybill.save"
                @onClick="sendData"
            ></button-with-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import csrModule, {
  BASE_URL,
  CREATE_ITEM,
  ERROR, GET_ITEM,
  ITEM,
  LOADING,
  MODULE_NAME,
  SUCCESS
} from "@/core/services/store/csr/csr.module";
import supplierCompaniesModule, {
  BASE_URL as BASE_URL_SUPPLIER_COMPANIES,
  GET_ITEMS as GET_SUPPLIER_COMPANIES,
  ITEMS as SUPPLIER_COMPANIES,
  MODULE_NAME as MODULE_SUPPLIER_COMPANIES
} from "@/core/services/store/supplier-company/supplier.company.module";

import companiesModule, {
  BASE_URL as BASE_URL_COMPANIES,
  GET_ITEMS as GET_COMPANIES,
  ITEMS as COMPANIES,
  MODULE_NAME as MODULE_COMPANIES
} from "@/core/services/store/company/company.module";

import supplierTypeModule, {
  BASE_URL as BASE_URL_SUPPLIER_TYPE,
  GET_ITEMS as GET_SUPPLIER_TYPES,
  ITEMS as SUPPLIER_TYPES,
  MODULE_NAME as MODULE_SUPPLIER_TYPE
} from "@/core/services/store/supplier-type/supplierType.module";

import processModule, {
  BASE_URL as BASE_URL_CSR_PROCESS,
  GET_ITEMS as GET_CSR_PROCESSES,
  ITEMS as CSR_PROCESSES,
  MODULE_NAME as MODULE_CSR_PROCESS
} from "@/core/services/store/csr-process/csrProcess.module";

import NumberInput from "@/assets/components/inputs/NumberInput";
import InputFilter from "@/assets/components/filters/InputFilter";
import TextInput from "@/assets/components/inputs/TextInput";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import MultipleSelectInput from "@/assets/components/inputs/MultipleSelectInput";
import SelectInput from "@/assets/components/inputs/SelectInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import moment from "moment";
import csrData from "@/view/pages/csr/csr-data.js";
import SampleIndexNavPanel from "@/view/pages/samples/layout/SampleIndexNavPanel";
import DashboardBox from "@/assets/components/DashboardBox";
import store from "@/core/services/store";

const _MODULE_NAME = MODULE_NAME;
const _MODULE_SUPPLIER_COMPANIES = MODULE_SUPPLIER_COMPANIES;
const _MODULE_COMPANIES = MODULE_COMPANIES;
const _MODULE_SUPPLIER_TYPE = MODULE_SUPPLIER_TYPE;
const _MODULE_CSR_PROCESS = MODULE_CSR_PROCESS;

export default {
  name: "AddEditCsr",
  components: {
    SampleIndexNavPanel,
    InputFilter,
    TextInput,
    DatePickerInput,
    MultipleSelectInput,
    SelectInput,
    CustomMultiSelect,
    ButtonWithIcon,
    NumberInput,
    DashboardBox
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }

    registerStoreModule(_MODULE_NAME, csrModule)
    registerStoreModule(_MODULE_SUPPLIER_COMPANIES, supplierCompaniesModule)
    registerStoreModule(_MODULE_COMPANIES, companiesModule)
    registerStoreModule(_MODULE_SUPPLIER_TYPE, supplierTypeModule)
    registerStoreModule(_MODULE_CSR_PROCESS, processModule)
  },
  computed: {
    error() {
      return store.getters[_MODULE_NAME + '/' + ERROR];
    },
    loading() {
      return store.getters[_MODULE_NAME + '/' + LOADING];
    },
    item() {
      return store.getters[_MODULE_NAME + '/' + ITEM];
    },
    success() {
      return store.getters[_MODULE_NAME + '/' + SUCCESS];
    },
    supplierCompanies() {
      let data = [];
      let self = this;
      let items = store.getters[_MODULE_SUPPLIER_COMPANIES + '/' + SUPPLIER_COMPANIES];
      if (items) {
        data = self.convertArrayToObjectByKey(items, 'id', 'name')
      }
      return data;
    },
    supplierTypes() {
      let data = [];
      let self = this;
      let items = store.getters[_MODULE_SUPPLIER_TYPE + '/' + SUPPLIER_TYPES];
      if (items) {
        data = self.convertArrayToObjectByKey(items, 'id', 'translations.0.name')
      }
      return data;
    },
    csrProcesses() {
      let data = [];
      let self = this;
      let items = store.getters[_MODULE_CSR_PROCESS + '/' + CSR_PROCESSES];
      if (items) {
        data = self.convertArrayToObjectByKey(items, 'id', 'translations.0.name')
      }
      return data;
    },
    companies() {
      let data = [];
      let self = this;
      let items = store.getters[_MODULE_COMPANIES + '/' + COMPANIES];
      if (items) {
        data = self.convertArrayToObjectByKey(items, 'id', 'name')
      }
      return data;
    },
    auditDataOptions() {
      let data = csrData.auditData;
      return this.convertArrayToObjectByKey(data, 'id', 'name')
    },
    yesNoOptions() {
      let data = csrData.yesNo;
      return this.convertArrayToObjectByKey(data, 'id', 'name')
    },
    disneyLicences() {
      let data = csrData.disneyLicences;
      return this.convertArrayToObjectByKey(data, 'id', 'name')
    },
    joinLifeOptions() {
      let data = csrData.joinLife;
      return this.convertArrayToObjectByKey(data, 'id', 'name')
    },
    certificateOptions() {
      let data = csrData.certificates;
      return this.convertArrayToObjectByKey(data, 'id', 'name')
    }
  },
  data() {
    return {
      today: moment(),
      id: this.$route.params.id,
      certificateStatus: false,
      form: {
        supplier_company_id: null,
        audit_results: {
          audit_social_id: null,
          audit_environment_id: null,
          audit_metal_id: null,
        },
        disney_licences: {
          disney_id: null,
          license_id: null,
        },
        process: {
          supplier_type_id: [],
          process_id: [],
        },
        join_life: [],
        company_id: [],
        certifications: {
          ocs_end_date: moment(),
          gots_end_date: moment(),
          rcs_end_date: moment(),
          grs_end_date: moment(),
          certificate_id: [],
        },
        status: false,
      }
    }
  },
  methods: {
    isValidToSubmit() {
      if (!this.form.supplier_company_id) {
        this.sweetAlertError(this.$t('csr.validations.supplier_company_id'));
        return false;
      }

      if (!this.form.company_id.length) {
        this.sweetAlertError(this.$t('csr.validations.company_id'));
        return false;
      }

      if (!this.form.disney_licences.disney_id) {
        this.sweetAlertError(this.$t('csr.validations.disney_id'));
        return false;
      }

      if (+this.form.disney_licences.disney_id === 1 && !this.form.disney_licences.license_id) {
        this.sweetAlertError(this.$t('csr.validations.license_id'));
        return false;
      }

      if (!this.form.process.process_id) {
        this.sweetAlertError(this.$t('csr.validations.process_id'));

        return false;
      }

      if (!this.form.process.supplier_type_id) {
        this.sweetAlertError(this.$t('csr.validations.supplier_type_id'));
        return false;
      }

      if (!this.form.audit_results.audit_social_id) {
        this.sweetAlertError(this.$t('csr.validations.audit_social_id'));

        return false;
      }

      if (!this.form.audit_results.audit_environment_id) {
        this.sweetAlertError(this.$t('csr.validations.audit_environment_id'));
        return false;
      }

      if (!this.form.audit_results.audit_metal_id) {
        this.sweetAlertError(this.$t('csr.validations.audit_metal_id'));
        return false;
      }

      if (!this.form.join_life.length) {
        this.sweetAlertError(this.$t('csr.validations.join_life'));
        return false;
      }

      if (!this.form.certifications.certificate_id.length) {
        this.sweetAlertError(this.$t('csr.validations.certificate_id'));
        return false;
      }

      return true;
    },
    getCsrItem() {
      this.$store.dispatch(_MODULE_NAME + '/' + GET_ITEM, {
        url: BASE_URL + '/' + this.id,
      })
    },
    getSupplierCompanies() {
      this.$store.dispatch(_MODULE_SUPPLIER_COMPANIES + '/' + GET_SUPPLIER_COMPANIES, {
        url: BASE_URL_SUPPLIER_COMPANIES,
        filters: {}
      })
    },
    getCompanies() {
      this.$store.dispatch(_MODULE_COMPANIES + '/' + GET_COMPANIES, {
        url: BASE_URL_COMPANIES,
        filters: {}
      })
    },
    getSupplierTypes() {
      this.$store.dispatch(_MODULE_SUPPLIER_TYPE + '/' + GET_SUPPLIER_TYPES, {
        url: BASE_URL_SUPPLIER_TYPE,
        filters: {}
      })
    },
    getCsrProcesses() {
      this.$store.dispatch(_MODULE_CSR_PROCESS + '/' + GET_CSR_PROCESSES, {
        url: BASE_URL_CSR_PROCESS,
        filters: {}
      })
    },
    sendData() {
      if (this.isUserGranted('Csr', ['create', 'update'])) {
        let result = this.isValidToSubmit();
        if (result) {
          this.$store.dispatch(_MODULE_NAME + '/' + CREATE_ITEM, {
            url: BASE_URL,
            contents: this.form,
          });
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("csr.self"), route: "csr.index"},
      {title: this.id ? this.$t("csr.update") : this.$t("csr.add")}
    ]);

    if (this.isUserGranted('Csr', ['create', 'update', 'view'])) {
      this.getSupplierCompanies();
      this.getCompanies();
      this.getSupplierTypes();
      this.getCsrProcesses();

      if (this.id) {
        this.getCsrItem();
      }
    }
  },
  watch: {
    'form.join_life': {
      handler: function (newValue, oldValue) {
/*
      this.certificateStatus = false;
      if (newValue) {
          if (newValue.includes("1")) {
            this.form.certifications.certificate_id = [];

            if (newValue.length > 1) {
              this.form.join_life = ["1"];
            }
          }

          if (newValue.includes("4")) {
            this.form.certifications.certificate_id = ["1", "3"];
            this.certificateStatus = true;
          }
          if (newValue.includes("5")) {
            this.form.certifications.certificate_id = ["2", "4"];
            this.certificateStatus = true;
          }
        }*/
      },
      deep: true
    },

    'form.certifications': {
      handler: function (newValue, oldValue) {
      if (newValue) {
          if (newValue.certificate_id.includes("0") && this.form.certifications.certificate_id.length > 1) {
            this.form.certifications.certificate_id = ["0"];
          }
       }
      },
      deep: true
    },
    item(value) {
      if (value) {
        if (this.id && value) {
          if (+value.status === 1) {
            value.status = true;
          } else {
            value.status = false;
          }
          this.form = value;
        }
      }
    },
    success(value) {
      if (value) {
        let self = this;
        this.sweetAlertClose();
        setTimeout(function () {
          self.sweetAlertSuccess(self.$t('general.successfully_saved')).then(() => {
            self.$router.push({name: 'csr.index'});
          })
        }, 500);
      }
    },
    error(newValue, oldValue) {
      let self = this;
      if (newValue != null && !this.disabledErrorPopUp) {
        setTimeout(() => {
          self.sweetAlertError(newValue);
        }, 1000)
      }
    },
  }
}


</script>
<style lang="scss">
/*Mobile Start*/
@import "../../../assets/sass/mobile/csr/addEditCsr.scss";
/*Mobile End*/
</style>
